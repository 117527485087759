import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import numeral from 'numeral'
import AliceCarousel from 'react-alice-carousel'

import { getAuth } from '../../redux/selectors/auth'
import CreditCard from '../../components/CreditCard/CreditCard'
import BoxWhite from '../../components/Box/BoxWhite'
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup'
import { mapBankCodeToBankLogoName } from '../../utils/mapBankCodeToBankName'
import { setWalletCredit } from '../../redux/actions/wallet'

import './Withdrawal.scss'
import Login from '../../components/Login/Login'
import 'react-alice-carousel/lib/alice-carousel.css'
// import mobile01 from '../../assets/bar01.jpg'
// import mobile02 from '../../assets/bar02.jpg'
// import mobile03 from '../../assets/bar03.jpg'
// import mobile04 from '../../assets/bar04.jpg'

// const imagesMobile = [mobile01, mobile04]

let timeoutId = null

// const SlideshowMobile = () => {
//   return (
//     <div className='home mobile' style={{ marginBottom: '10px' }}>
//       {/*<div className='home__marquee'>*/}
//       {/*  <Marquee speed={0.04} delay={3000} direction='left'>*/}
//       {/*    ยินดีต้อนรับสู่ ป้าตุ๊ก.com เว็บหวยที่ดีที่สุด จ่ายเยอะที่สุดในชั่วโมงนี้ ฉลองเปิดตัวเว็บใหม่*/}
//       {/*    สองตัวบนล่างบาทละ 100 บาท สามตัวตรงบาทละ 900 บาท พร้อมระบบการจ่ายรางวัลและฝากถอน ที่รวดเร็วที่สุดในประเทศ*/}
//       {/*    เว็บดีๆ จงบอกเพื่อน*/}
//       {/*  </Marquee>*/}
//       {/*</div>*/}
//       <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
//         {imagesMobile.map((each, index) => (
//           <img key={index} className='home__sliderimg' src={each} alt='slide' />
//         ))}
//       </AliceCarousel>
//     </div>
//   )
// }
//
// const SlideshowPc = () => {
//   return (
//     <div className='home desktop' style={{ marginBottom: '10px' }}>
//       {/*<div className='home__marquee'>*/}
//       {/*  <Marquee speed={0.04} delay={3000} direction='left'>*/}
//       {/*    ยินดีต้อนรับสู่ ป้าตุ๊ก.com เว็บหวยที่ดีที่สุด จ่ายเยอะที่สุดในชั่วโมงนี้ ฉลองเปิดตัวเว็บใหม่*/}
//       {/*    สองตัวบนล่างบาทละ 100 บาท สามตัวตรงบาทละ 900 บาท พร้อมระบบการจ่ายรางวัลและฝากถอน ที่รวดเร็วที่สุดในประเทศ*/}
//       {/*    เว็บดีๆ จงบอกเพื่อน*/}
//       {/*  </Marquee>*/}
//       {/*</div>*/}
//       <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
//         {imagesMobile.map((each, index) => (
//           <img key={index} className='home__sliderimg' src={each} alt='slide' />
//         ))}
//       </AliceCarousel>
//     </div>
//   )
// }

const Withdrawal = () => {
  const history = useHistory()
  const auth = useSelector(getAuth)
  const dispatch = useDispatch()
  const username = auth.username
  const walletCredit = useSelector((state) => _get(state, 'wallet.credit', null))
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const [playerAccountId, setPlayerAccountId] = useState('')
  const [playerBankAccount, setPlayerBankAccount] = useState('')
  const [selectedPlayerBankAccount, setSelectedPlayerBankAccount] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [remarkValue, setRemarkValue] = useState('')
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })

  const _onWithdrawAmountChange = (e) => {
    const value = e.target.value
    if (value === '' || /^\d+(\.\d{0,2})?$/g.test(value)) {
      setWithdrawAmount(value)
    }
  }

  const fetchWallet = async () => {
    return await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
      .then((res) => {
        const credit = _get(res, 'detail.credit', 0)
        dispatch(
          setWalletCredit({
            wallet: {
              credit,
            },
          })
        )
        return credit
      })
      .catch((err) => {
        console.log('error get playerWallet from noti ======', err)
        console.error(err)
      })
  }

  useEffect(() => {
    // Swal.fire({
    //   icon: 'warning',
    //   title: 'ขออภัยระบบถอนปิดปรับปรุงชั่วคราว เนื่องจากมีปริมาณการถอนเป็นจำนวนมาก คุณลูกค้าสามารถทำการถอนได้อีกครั้งหลังเที่ยงคืน ขออภัยในความไม่สะดวกครับ',
    // })
    setIsLoading(true)

    if (!username) {
      // Not login yet
      timeoutId = setTimeout(() => {
        Swal.fire({
          icon: 'error',
          title: 'กรุณาเข้าสู่ระบบ',
          text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
          showCancelButton: true,
          confirmButtonColor: '#B6964E',
          cancelButtonColor: ' #A9A9A9',
          confirmButtonText: 'เข้าสู่ระบบ',
        }).then((result) => {
          if (result.value) {
            setShowModal({ isShow: true, currentModal: 'login' })
          }
        })

      }, 1500)
      setIsLoading(false)

    } else {
      clearTimeout(timeoutId)
      fetchWallet()

      API.get('playerBankAccount', `/players/${username}/bank-account`)
        .then((res) => {
          const playerBankAccount = _get(res, 'detail', null)
          if (playerBankAccount && !_isEmpty(playerBankAccount)) {
            console.log('playerBankAccount >>>', playerBankAccount)
            if (playerBankAccount.status === 'active') {
              setPlayerBankAccount(playerBankAccount)
              setPlayerAccountId(playerBankAccount._id)
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'ไม่พบบัญชีธนาคารของคุณ',
            })
          }
        })
        .catch((err) => {
          console.log('error get bank account ======')
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'ไม่พบบัญชีธนาคารของคุณ',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [username])

  const _onClickFirstButton = () => {
    history.push('/')
  }

  const _onClickSecondButton = async () => {
    const amountNumber = Number(withdrawAmount)
    // Validate
    if (!amountNumber || amountNumber < 300) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณาระบุจำนวนเงินด้วยตัวเลขขั้นต่ำ 300 บาท',
      })
      return
    }
    if (amountNumber > 49000) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณาระบุจำนวนเงินด้วยตัวเลข ไม่เกินครั้งละ 49,000 บาท',
      })
      return
    }

    const walletBalance = await fetchWallet()

    if (amountNumber > walletBalance) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: `ยอดเงินที่ถอนได้ (${numeral(walletBalance).format('0,0.00')} บาท) ไม่เพียงพอ`,
      })
      return
    }

    if (!playerAccountId) {
      return Swal.fire({
        icon: 'error',
        title: 'ไม่พบบัญชีธนาคารของคุณ',
      })
    }

    // if (!selectedPlayerBankAccount) {
    //   return Swal.fire({
    //     icon: 'error',
    //     title: 'กรุณาเลือกบัญชีธนาคารของคุณที่ต้องการถอนเงินเข้า',
    //   })
    // }

    setIsWithdrawing(true)

    API.post('payment', '/transactions', {
      body: {
        playerUsername: username,
        playerAccountId: playerAccountId,
        collectionAccountId: username,
        transactionType: 'withdraw',
        amount: amountNumber,
        remarkValue: remarkValue,
      },
    })
      .then((res) => {
        const transactionNumber = _get(res, 'detail', null)
        if (transactionNumber) {
          return Swal.fire({
            icon: 'success',
            title: 'ส่งคำสั่งถอนเงินสำเร็จ',
            // text: `หมายเลขธุรกรรม (Transaction number: ${transactionNumber})`,
            text: 'หากภายใน 5 นาทียอดเงินไม่เข้าบัญชี โปรดกรุณาติดต่อเจ้าหน้าที่ครับ',
          })
        } else {
          throw new Error('ระบบผิดพลาด ไม่พบหมายเลขธุรกรรม Transaction number')
        }
      })
      .catch((err) => {
        console.log('error withdraw ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.message,
        })
      })
      .finally(() => {
        setIsWithdrawing(false)
        fetchWallet()
      })
  }

  const _onClickPlayerBankAccount = (e) => {
    e.preventDefault()
    setSelectedPlayerBankAccount(!selectedPlayerBankAccount)
  }

  const bankLogoName = playerBankAccount ? mapBankCodeToBankLogoName[playerBankAccount.bankCode] || 'kplus' : 'kplus'

  return (
    <section className='withdrawal'>
      <div className='home__banking-action mt-20'>
        <div>
          <Link to='/deposit'>
            <button className='px-10 py-2 btn-bevel green'>
              <span>+ ฝากเงิน</span>
            </button>
          </Link>
        </div>
        <div>
          <Link to='/withdraw'>
            <button className='px-10 py-2 btn-bevel red' style={{ border: '4px ridge #B9A97D' }}>
              <span>- ถอนเงิน</span>
            </button>
          </Link>
        </div>
      </div>
      {/*<SlideshowMobile />*/}
      {/*<SlideshowPc />*/}
      <CreditCard />
      {/*<Box containerClass='withdrawal__box'>*/}
      {/*  <span className='deposit__step'>แจ้งถอนเงิน</span>*/}
      {/*  <p className='withdrawal__box-bankChoice withdrawal__withIcon-choose'>บัญชีธนาคารของท่าน</p>*/}
      {/*  /!* <p className='withdrawal__redRemark'>*หมายเหตุ</p> *!/*/}
      {/*  <div className='withdrawal__bankWrapper'>*/}
      {/*    <div className='withdrawal__bankDetail'>*/}
      {/*      {playerBankAccount ? (*/}
      {/*        <img src={require(`../Deposit/images/${bankLogoName}-logo.png`)} alt={`${bankLogoName}-logo`} />*/}
      {/*      ) : null}*/}
      {/*      <div className='withdrawal__bankDetail_user'>*/}
      {/*        <p className='withdrawal__bankDetail_accountNumber' style={{ color: '#41aa34' }}>*/}
      {/*          {!isLoading && playerBankAccount*/}
      {/*            ? _get(playerBankAccount, 'accountNumber', '')*/}
      {/*            : !isLoading && !playerBankAccount*/}
      {/*            ? 'ไม่พบบัญชีธนาคารของคุณ'*/}
      {/*            : isLoading*/}
      {/*            ? 'กำลังโหลด...'*/}
      {/*            : null}*/}
      {/*        </p>*/}
      {/*        <p>ชื่อบัญชี</p>*/}
      {/*        <div className='withdrawal__bankDetail_userInfo'>*/}
      {/*          <p>{!isLoading ? _get(playerBankAccount, 'accountName', '') : 'กำลังโหลด...'}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    /!*  <div*/}
      {/*      className={`withdrawal__bank${selectedPlayerBankAccount && playerBankAccount ? ' active' : ''}`}*/}
      {/*      // onClick={_onClickPlayerBankAccount}*/}
      {/*    >*/}
      {/*      {!isLoading && playerBankAccount ? (*/}
      {/*        <div>*/}
      {/*          <img src={require(`../Deposit/images/${bankLogoName}-logo.png`)} alt={`${bankLogoName}-logo`} />*/}
      {/*          <p>*/}
      {/*            <span>{_get(playerBankAccount, 'bankName', 'ไม่พบบัญชีธนาคารของคุณ')}</span>*/}
      {/*            <span>{_get(playerBankAccount, 'accountNumber', '')}</span>*/}
      {/*          </p>*/}
      {/*        </div>*/}
      {/*      ) : (*/}
      {/*        <div>*/}
      {/*          <p style={{ color: 'black' }}>ไม่พบบัญชีธนาคารของคุณ</p>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </div> *!/*/}
      {/*  </div>*/}
      {/*</Box>*/}
      <BoxWhite withFlare={false} withShine={false} containerClass='withdrawal__box withdrawal__info'>
        <div className='withdrawal__withIcon text-black'>
          <p className='withdrawal__withIcon-receive'>จำนวนเงินที่ถอนได้</p>
          <p className='withdrawal__balance'>{numeral(walletCredit).format('0,0.00')} บาท</p>
        </div>
        <div className='withdrawal__withIcon text-black'>
          <p className='withdrawal__withIcon-cash'>จำนวนเงินที่ต้องการถอน</p>
          <input
            type='text'
            name='withdraw-value'
            id='withdraw-value'
            color='#72721f'
            placeholder='500 - 49,000 ต่อครั้ง'
            value={withdrawAmount}
            onChange={_onWithdrawAmountChange}
            style={{ borderRadius: '0px' }}
          />
        </div>
      </BoxWhite>
      {/* <Box
        withFlare={false}
        withShine={false}
        containerClass='withdrawal__box withdrawal__remarkBox withdrawal__withIcon'
      >
        <p className='withdrawal__withIcon-star'>หมายเหตุ</p>
        <textarea
          className='__remark-input'
          type='text'
          rows='10'
          value={remarkValue}
          onChange={(e) => setRemarkValue(e.target.value)}
        />
      </Box> */}
      <ButtonGroup
        withBorder
        onClickFirstButton={_onClickFirstButton}
        onClickSecondButton={_onClickSecondButton}
        disabled={isWithdrawing}
      />
      {!auth.username ? <Login showModal={showModal} setShowModal={setShowModal} /> : null}
    </section>
  )
}

export default Withdrawal
